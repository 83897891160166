import React from 'react';

// material-ui
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MainCard from './MainCard';
import { makeStyles } from 'tss-react/mui';

// ==============================|| CUSTOM MAIN CARD ||============================== //



const ContentCard = React.forwardRef(
    (
        { title,
            link,
            subTitle,
            icon,
            children
        },
        ref
    ) => {
        const { classes } = useStyles();
        return (
            <div className={classes.contentcard} >
                <div className={classes.sideBorder}></div>

                <MainCard  >
                    {link ? (<Link to={link}  >
                        <div  >

                            <Typography color="primary" variant="h3" style={{ fontWeight: 'bold' }}>
                                {title}
                            </Typography>
                            {icon && <IconButton sx={{ background: (theme) => theme.palette.gradiant.main, color: 'white', float: "right" }}  >

                                {icon}
                            </IconButton>}
                            <Typography variant="h4" color="text.primary" style={{ marginTop: "20px" }}>
                                {subTitle}
                            </Typography>
                            {children}
                        </div>

                    </Link>) : (<>
                        <Typography color="primary" variant="h3"  style={{ fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        {icon && <IconButton sx={{ background: (theme) => theme.palette.gradiant.main, fontSize: 28, color: 'white', float: "right" }}  >
                            {icon}
                        </IconButton>}
                        <Typography variant="h4" color="text.primary" style={{ marginTop: "20px" }}>
                            {subTitle}
                        </Typography>
                        {children}
                    </>
                    )}

                </MainCard>
            </div>
        );
    }
);
const useStyles = makeStyles({ "name": { ContentCard } })((theme) => ({
    contentcard: {
        minHeight: '110px',
        height:'100%!important'
    },
    sideBorder: {
        width: '3px',
        backgroundColor: theme.palette.success.main,
        position: 'absolute',
        minHeight: '139px',
        borderRadius: '20px' 
    }
}));
export default ContentCard;
