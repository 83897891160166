import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import Layout from './components/Layout/Layout'
import "@fontsource/maven-pro"
import { ThemeProvider } from '@mui/material/styles';

import themes from './themes';
import { HelmetProvider } from 'react-helmet-async';

const customization = {
    borderRadius: 10,
    fontFamily: "'Roboto', sans-serif",
    locale: "en",
    mode: "light",
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <Provider store={store}>
            <HelmetProvider>
                <BrowserRouter >


                    <ThemeProvider theme={themes(customization)} >

                        <Layout>

                            <App />

                        </Layout>

                    </ThemeProvider>

                </BrowserRouter>
            </HelmetProvider>
        </Provider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
