import { alpha } from '@mui/material/styles';

// project imports

const createCustomShadow = (theme, color) => {
    return {
       
        primary: `0px 12px 14px 10px ${alpha(theme.colors?.primaryMain, 0.3)}`,
        secondary: `0px 12px 14px 10px ${alpha(theme.colors?.secondaryMain, 0.3)}`,
        success: `0px 12px 14px 0px ${alpha(theme.colors?.successMain, 0.3)}`,
        warning: `0px 12px 14px 0px ${alpha(theme.colors?.warningMain, 0.3)}`,
        error: `0px 12px 14px 0px ${alpha(theme.colors?.errorMain, 0.3)}`
    };
};

export default function customShadows(mode, theme) {
    return mode === 'dark' ? createCustomShadow(theme, theme.colors?.darkLevel1) : createCustomShadow(theme, theme.colors?.grey600);
}
