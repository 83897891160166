import React from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';


// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //



const MainCard = React.forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        },
        ref
    ) => {
        const {classes} = useStyles();
        return (
            <Card
                ref={ref}
                className={classes.maincard}
              
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent   className={classes.maincardcontent} >
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);
const useStyles = makeStyles({ "name": { MainCard } })((theme) => ({
    maincard:{
        boxShadow:theme.customShadows.secondary,
        overflow: 'auto',
        height: '100%!important'

    },
    maincardcontent:{
        padding: '5px',
        minHeight:'100px'
    }
}));
export default MainCard;
