import React from 'react';

import {
    Paper
} from '@mui/material';
import PlaceholderLoading from 'react-placeholder-loading'
import { makeStyles } from 'tss-react/mui';


const Loader = () => {
    const {classes} = useStyles()
    return (
        <div className={classes.divWrapper} >
            <Paper className={classes.divWrapperPaper} >
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />
                <PlaceholderLoading shape="rect" width={"100%"}   height={5} /><br />

            </Paper>



        </div>)
}

const useStyles = makeStyles({ "name": { Loader } })((theme) => ({
    divWrapper: {
        margin: '10px',
        marginTop: '50px'
    },
    divWrapperPaper: {
        padding: '10px'
    }
}));
export default Loader;