
import { createSlice } from '@reduxjs/toolkit'; 
const initialState = {
    status:true

}
const initializedSlice = createSlice({
    name: 'initialized',
    initialState,
    reducers: {
        initializedError: (state,action) => {
            state.status = false;
  
        },
        initializedSuccess: (state, action) => {
            state.status = true;
         }
    }
})
export const { initializedSuccess, initializedError } =initializedSlice.actions;

export default initializedSlice.reducer;